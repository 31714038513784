import React from 'react'
import ResearchList from '../lists/ResearchList'
const Research = () => {
  return (
    <div style={{padding:"26px",backgroundColor:'white',margin:"0px",}}>
      
      
      <ResearchList />
      
      
    </div>
  )
}

export default Research