import React from 'react';
import GalleryList from '../lists/GalleryList';
const Gallery = () => {
  return (
    <div style={{padding:"26px",backgroundColor:'white',margin:"0px",}}>
      
      
      <GalleryList />
      
      
    </div>
  )
}

export default Gallery;