import React from 'react';
import MessageList from '../lists/MessageList';
const Message = () => {
  return (
    <div style={{padding:"26px",backgroundColor:'white',margin:"0px",}}>
      
      
      <MessageList />
      
      
    </div>
  )
}

export default Message;