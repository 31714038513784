import React from 'react'
import MemberList from '../lists/MemberList'
const Members = () => {
  return (
    <div style={{padding:"26px",backgroundColor:'white',margin:"0px",}}>
      
      
      <MemberList />
      
      
    </div>
  )
}

export default Members