import React from 'react';
import CollaboratorList from '../lists/CollaboratorList';
const Collaborator = () => {
  return (
    <div style={{padding:"26px",backgroundColor:'white',margin:"0px",}}>
      
      
      <CollaboratorList />
      
      
    </div>
  )
}

export default Collaborator;