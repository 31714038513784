import React from 'react';
import PublicationList from '../lists/PublicationList';
const Publication = () => {
  return (
    <div style={{padding:"26px",backgroundColor:'white',margin:"0px",}}>
      
      
      <PublicationList />
      
      
    </div>
  )
}

export default Publication;